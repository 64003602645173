<!--
 * @Description: 添加客户关系弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-01 09:25:55
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 新增客户关系弹框 -->
  <el-dialog
    v-dialogDrag
    title="新增客户关系"
    :visible.sync="addVisible"
    width="500px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="add-customer-dialog"
  >
    <el-form
      ref="addForm"
      label-width="88px"
      :model="addForm"
      :rules="addUserRules"
    >
      <el-form-item label="公司名称" prop="otherCorpId">
        <e6-vr-select
          v-model="addForm.otherCorpId"
          :data="cropList"
          placeholder="公司名称"
          title="公司名称"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="客户关系" prop="corpRelationCode">
        <e6-vr-select
          v-model="addForm.corpRelationCode"
          :data="relationshipList"
          placeholder="客户关系"
          title="客户关系"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model.trim="addForm.remark"
          placeholder="备注"
          maxlength="100"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="pushData">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "",
  components: {},
  data() {
    return {
      cropList: [], //公司名称下拉框数据
      relationshipList: [
        {
          id: 1,
          label: "总分公司"
        },
        {
          id: 2,
          label: "总子公司"
        },
        {
          id: 3,
          label: "竞争对手"
        }
      ], //客户关系下拉框
      dialogLoading: false,
      addForm: {
        otherCorpId: "", //公司名称
        corpRelationCode: "", //客户关系
        remark: "" //备注
      },
      addUserRules: {
        otherCorpId: [
          {
            required: true,
            message: "请选择公司名称",
            trigger: ["blur", "change"]
          }
        ],
        corpRelationCode: [
          {
            required: true,
            message: "请选择客户关系",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //点击确定
    pushData() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          //公司名称
          let otherCorpInfo = this.cropList.filter(
            item => item.id == this.addForm.otherCorpId
          );
          this.addForm.otherCorpName = otherCorpInfo[0].label;
          //客户关系
          let corpRelationInfo = this.relationshipList.filter(
            item => item.id == this.addForm.corpRelationCode
          );
          this.addForm.corpRelationName = corpRelationInfo[0].label;
          this.$emit("handlePush", {
            type: 2,
            data: _.cloneDeep(this.addForm)
          });
          this.closeDialog();
        }
      });
    },
    //点击取消
    closeDialog() {
      this.addForm = {
        otherCorpId: "", //公司名称
        corpRelationCode: "", //客户关系
        remark: "" //备注
      };
      this.$refs.addForm.resetFields();
      this.$emit("handleClose", "addVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/ .add-customer-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
  }
  .el-form {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
</style>
